.fields {
  .tab-container {
    margin-bottom: 0;

    .tab-button {
      width: 170px;
      border: 2px solid transparent;
      height: 52px;
      justify-content: space-around;
      box-shadow: none;

      &:last-child {
        margin-right: 0;
      }

      &.invalid {
        border: 2px solid $colour_bad;
      }

      &.selected {
        border: 2px solid $colour_highlight;
        background-color: white;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .warning {
    color: #ed4646;
  }

  .button-holder {
    justify-content: flex-end;
    display: flex;
  }

  .floating-header {
    margin-right: 0;
    align-items: center;

    h3 {
      margin: 0;
    }

    .button-holder {
      display: flex;
      align-items: center;

      button {
        line-height: 16px;
      }
    }
  }

  .form-section {
    display: flex;
    flex-wrap: wrap;

    label {
      margin-left: 18px;
      width: 257px;
    }

    select,
    input,
    textarea {
      width: 257px;
    }

    input,
    textarea {
      &:read-only {
        background-color: transparent;
        border: 2px solid white;
      }
    }

    ::placeholder {
      color: #a4a4a4;
    }

    .document-upload {
      width: 35px;
      position: absolute;
      right: -40px;
      line-height: 29px;
      font-size: 20px;
    }
  }

  button {
    margin-left: 15px;

    &.action {
      line-height: 16px;
    }
  }

  span.required {
    color: #d3372a;
  }
}