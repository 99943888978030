.icon {
  width: 90px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;

  &.icon-orderly {
    background-image: url("/assets/icons/Order Management - Box.svg");
    height: 62px;
    width: 55px;
    margin-left: 18px;
    margin-right: 18px;
  }

  &.icon-customer-service {
    background-image: url("/assets/icons/Customer Service - Cup.svg");
    height: 78px;
    width: 46px;
    margin-left: 23px;
    margin-right: 23px;
  }

  &.icon-customer-service-light {
    background-image: url("/assets/icons/Customer Service - Cup light.svg");
    height: 78px;
    width: 46px;
    margin-left: 23px;
    margin-right: 23px;
  }

  &.icon-item-catalogue {
    background-image: url("/assets/icons/Item Catalogue - Catalogue.svg");
    height: 70px;
    width: 50px;
    margin-left: 23px;
    margin-right: 18px;
  }

  &.icon-http {
    background-image: url("/assets/icons/http.png");
    background-size: contain;
    height: 90px;
    width: 90px;
    margin-left: 5px;
    margin-right: 0;
  }

  &.icon-document-libary {
    background-image: url("/assets/icons/DL - Man with Files.svg");
    height: 67px;
    width: 64px;
    margin-left: 15px;
    margin-right: 12px;
  }

  &.icon-qa {
    background-image: url("/assets/icons/QA - Starry Star.svg");
    height: 53px;
    width: 53px;
    margin-left: 17px;
    margin-right: 21px;
  }

  &.icon-qa-light {
    background-image: url("/assets/icons/QA - Starry Star light.svg");
    height: 53px;
    width: 53px;
    margin-left: 17px;
    margin-right: 21px;
  }

  &.icon-pricing {
    background-image: url("/assets/currency-icons/Mermaids.svg");
    height: 53px;
    width: 48px;
    margin-left: 21px;
    margin-right: 23px;

    &.eur {
      background-image: url("/assets/currency-icons/EUR.svg");
    }

    &.gbp {
      background-image: url("/assets/currency-icons/GBP.svg");
    }

    &.usd {
      background-image: url("/assets/currency-icons/USD.svg");
    }
  }

  &.icon-forecasting {
    background-image: url("/assets/icons/Forecasting - Calendar.svg");
    height: 64px;
    width: 60px;
    margin-left: 17px;
    margin-right: 15px;
  }

  &.icon-inventory {
    background-image: url("/assets/icons/Orderly Inventory - Logo.svg");
    height: 60px;
    width: 64px;
    margin-left: 15px;
    margin-right: 13px;
  }

  &.icon-inventory-light {
    background-image: url("/assets/icons/Orderly Inventory - Logo light.svg");
    height: 60px;
    width: 64px;
    margin-left: 15px;
    margin-right: 13px;
  }

  &.icon-reports {
    background-image: url("/assets/icons/Reports - XLS.svg");
    height: 71px;
    width: 54px;
    margin-left: 19px;
    margin-right: 18px;
  }

  &.icon-admin {
    background-image: url("/assets/icons/Admin - Man.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-history {
    background-image: url("/assets/icons/History.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-announcements {
    background-image: url("/assets/icons/Notifications.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-mobile {
    background-image: url("/assets/icons/mobile-svg-icon-myorderly.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-policies {
    background-image: url("/assets/icons/Policies - Filey Files.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-file {
    background-image: url("/assets/icons/File Icon.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;

    &.xls,
    &.xlsx,
    &.ods {
      background-image: url("/assets/file-icons/XLS.svg");
    }

    &.doc,
    &.docx,
    &.odt {
      background-image: url("/assets/file-icons/Doc.svg");
    }

    &.webm,
    &.mkv,
    &.flv,
    &.vob,
    &.ogv,
    &.ogg,
    &.drc,
    &.gifv,
    &.avi,
    &.mov,
    &.qt,
    &.wmv,
    &.yuv,
    &.rm,
    &.rmvb,
    &.asf,
    &.amv,
    &.mp4,
    &.m4p,
    &.m4v,
    &.mpg,
    &.mp2,
    &.mpeg,
    &.mpe,
    &.mpv,
    &.m2v,
    &.m4v

    /*,
    &.3gp,
    &.3g2*/
      {
      background-image: url("/assets/file-icons/Vid.svg");
    }

    &.pdf {
      background-image: url("/assets/file-icons/PDF.svg");
    }

    &.ppt,
    &.pptx,
    &.pttm,
    &.odp,
    &.key {
      background-image: url("/assets/file-icons/ppt.svg");
    }

    &.jpg,
    &.jpe,
    &.jpeg,
    &.exif,
    &.tiff,
    &.tif,
    &.bmp,
    &.png,
    &.gif {
      background-image: url("/assets/file-icons/Img.svg");
    }
  }

  &.icon-folder {
    background-image: url("/assets/icons/Folder Icon.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-form {
    background-image: url("/assets/icons/Form Icon.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-table {
    background-image: url("/assets/icons/Table Icon.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-hazardous {
    background-image: url("/assets/icons/Hazardous Materials Information.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-speedo {
    background-image: url("/assets/icons/Speedo.svg");
    width: 67px;
    height: 100px;
    margin-left: 13px;
    margin-right: 11px;
  }

  &.icon-alert {
    background-image: url("/assets/icons/Alert Management.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-food-safety {
    background-image: url("/assets/icons/Food Safety.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-recipes {
    background-image: url("/assets/icons/Receipies and BOMs.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-upload {
    background-image: url("/assets/icons/Upload Icon.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-upload-dark {
    background-image: url("/assets/icons/Upload Icon.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-user-management {
    background-image: url("/assets/icons/User Management.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-store {
    background-image: url("/assets/icons/store.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-stores {
    background-image: url("/assets/icons/stores.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-overdue-invoices {
    background-image: url("/assets/icons/Overdue Invoices.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-orderly-logo {
    background-image: url("/assets/icons/Orderly Logo.svg");
    width: 100px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-external-link {
    position: absolute;
    background-image: url("/assets/icons/Open External Application.svg");
    width: 30px;
    height: 20px;
    top: 7px;
    right: 3px;
  }

  &.icon-external-download {
    position: absolute;
    background-image: url("/assets/icons/Download light small.svg");
    width: 30px;
    height: 20px;
    top: 7px;
    right: 3px;
  }

  &.icon-star1 {
    background-image: url("/assets/icons/StarFilled1.svg");
    width: 50px;
    height: 50px;
  }

  &.icon-star2 {
    background-image: url("/assets/icons/StarFilled2.svg");
    width: 50px;
    height: 50px;
  }

  &.icon-star3 {
    background-image: url("/assets/icons/StarFilled3.svg");
    width: 50px;
    height: 50px;
  }

  &.icon-star4 {
    background-image: url("/assets/icons/StarFilled4.svg");
    width: 50px;
    height: 50px;
  }

  &.icon-star5 {
    background-image: url("/assets/icons/StarFilled5.svg");
    width: 50px;
    height: 50px;
  }

  &.icon-star-empty {
    background-image: url("/assets/icons/StarEmpty.svg");
    width: 50px;
    height: 50px;
  }

  &.icon-image-upload {
    background-image: url("/assets/icons/Image Upload.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-image-remove-dark {
    background-image: url("/assets/icons/Image Remove2.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-image-remove {
    background-image: url("/assets/icons/Image Remove.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-messages {
    background-image: url("/assets/icons/Information.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-messages-light {
    background-image: url("/assets/icons/InformationLight.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-tableau {
    background-image: url("/assets/icons/Tableau.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-download-black {
    background-image: url("/assets/icons/Download-black.svg");
    width: 53px;
    height: 53px;
    margin-left: 17px;
    margin-right: 17px;
  }

  &.icon-download {
    background-image: url("/assets/icons/Download.svg");
    width: 53px;
    height: 53px;
    margin-left: 17px;
    margin-right: 17px;
  }

  &.icon-download-light {
    background-image: url("/assets/icons/Download light.svg");
    width: 53px;
    height: 53px;
    margin-left: 17px;
    margin-right: 17px;
  }

  &.icon-expand {
    background-image: url("/assets/icons/DownArrow dark.svg");
    width: 15px;
    height: 15px;
    transform: rotate(180deg);
  }

  &.icon-collapse {
    background-image: url("/assets/icons/DownArrow dark.svg");
    width: 15px;
    height: 15px;
  }

  &.icon-notification-messages {
    background-image: url("/assets/notification-icons/Information.svg");
    width: 63px;
    height: 71px;
    margin-left: 13px;
    margin-right: 15px;
  }

  &.icon-notification-box {
    background-image: url("/assets/notification-icons/Order Management - Box.svg");
    height: 62px;
    width: 55px;
    margin-left: 18px;
    margin-right: 18px;
  }

  &.icon-notification-alert {
    background-image: url("/assets/notification-icons/Alert Management.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-item {
    background-image: url("/assets/notification-icons/Item Catalogue - Catalogue.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-document {
    background-image: url("/assets/notification-icons/File Icon.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-qa {
    background-image: url("/assets/notification-icons/QA - Starry Star.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-pricing {
    background-image: url("/assets/notification-icons/Pricing Catalogue - Euro.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-forecasting {
    background-image: url("/assets/notification-icons/Forecasting - Calendar.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-orderly {
    background-image: url("/assets/notification-icons/Orderly Inventory - Logo.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-report {
    background-image: url("/assets/notification-icons/Reports - XLS.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.icon-notification-policy {
    background-image: url("/assets/notification-icons/Policies - Filey Files.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
  }
}