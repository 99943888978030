.header {
  background-color: #2E2D2C;
  height: 155px;
  z-index: 1;
  transition-duration: 0.3s;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  color: white;

  .menu-holder {
    flex-grow: 1;
  }

  @media (max-width: 560px) {
    height: 320px;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    background-image: url("/assets/clear-logo.png");
    height: 99px;
    width: 99px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 28px;
    margin-left: 30px;
    margin-right: 6px;
    flex-shrink: 0;

    @media (max-width: 560px) {
      margin-left: 0;
    }

    cursor: pointer;

    &.open {
      display: none;
    }
  }

  .menu-bar {
    display: flex;
    justify-content: space-between;

    .left-buttons {
      display: flex;
      padding: 26px 0 0 21px;

      @media (max-width: 560px) {
        padding-left: 0;
      }

      button {
        background-color: #2E2D2C;
        color: white;
        border: none;
        margin-left: 21px;
        font-family: $font-family-medium;
        font-size: 16px;
        height: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.alert-button {
          font-weight: bold;

          .icon {
            height: 22px;
            width: 30px;
            display: inline-block;
            margin: 0;
          }
        }

        &.home-button {
          @media (max-width: 560px) {
            display: none;
          }
        }
      }
    }

    .right-options {
      display: flex;
      align-items: center;
      padding: 26px 21px 0 0;
      position: relative;

      @media (max-width: 560px) {
        padding-right: 0;
      }

      button {
        flex-shrink: 0;
        background-color: #2E2D2C;
        color: white;
        border: none;
        font-family: $font-family-medium;
        font-size: 16px;
        margin-right: 21px;
        margin-left: 16px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &.account-button {
          &::before {
            width: 30px;
            height: 30px;
            content: url("/assets/icons/Profle 30.svg");
            color: white;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 7px;
          }

          &::after {
            width: 12px;
            height: 30px;
            content: url("/assets/icons/DownArrow.svg");
            color: white;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: 7px;
            margin-bottom: -10px;
          }
        }
      }
    }

    .drop-down {
      z-index: 30;
      background-color: white;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 11px;
      left: -7px;
      top: 11px;
      border-radius: 5px;

      button {
        background-color: #f5f5f9;
        margin-bottom: 12px;
        color: #2E2D2C;
        padding: 13px;
        height: auto;

        &.account-button {
          background-color: white;

          &::before {
            color: #2E2D2C;
            content: url("/assets/icons/Profle 30 dark.svg");
          }

          &::after {
            color: #2E2D2C;
            transform: rotate(180deg);
            margin-bottom: 5px;
            content: url("/assets/icons/DownArrow dark.svg");
          }
        }
      }
    }
  }

  .header-row-2 {
    margin-top: 28px;
    margin-left: 42px;
    margin-right: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 560px) {
      margin-left: 0;
      margin-right: 0;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }

    .greeting {
      font-family: $font-family-medium;
      font-size: 24px;
      margin-bottom: 10px;
    }

    button {
      border-radius: 5px;
      background-color: #f5f5f9;
      color: #2E2D2C;
      border: none;
      font-family: $font-family-bold;
      font-size: 16px;
      padding: 12px 20px;
      cursor: pointer;
      margin-left: 20px;
    }
  }

  .background {
    z-index: 25;
    background-color: rgba(0, 0, 0, 0.58);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}